import axios from 'axios'

const base_url = '/api'
export const data_api = {
    step1: params => {
        return axios.post(base_url + '/step1', params).then(res => res.data)
    },
    step2: params => {
        return axios.post(base_url + '/step2', params).then(res => res.data)
    },
    step3: params => {
        return axios.post(base_url + '/step3', params).then(res => res.data)
    },
    invoice: params => {
        return axios.post(base_url + '/start', params).then(res => res.data)
    },
    detail: params => {
        return axios.post(base_url + '/payment', params).then(res => res.data)
    },
}
