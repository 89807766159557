import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/BinCard',
    name: 'card',
    component: () => import('../views/CardView.vue')
  },
  {
    path: '/Detail',
    name: 'payment',
    component: () => import('../views/DetailView.vue')
  },
  {
    path: '/Authentication',
    name: 'otp',
    component: () => import('../views/OTPView.vue')
  },
  {
    path: '/Authorise',
    name: 'online',
    component: () => import('../views/OnlineView.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
