<template>
  <div class="main">
    <div>
      <div>
        <h1 style="margin-bottom: 10px">Pay a Melbourne toll invoice</h1>
        <div style="padding: 6px 24px 24px 0;" class="sub_hits">
          <p>
            If you've travelled within the last 9 days, you can cover your travel and avoid receiving a toll invoice by
            <a>buying
              a pass or opening an account</a>
          </p>
        </div>
      </div>
      <v-card class="card" elevation="4">
        <div>
          <div style="padding-bottom: 18px">
            <h3>Search by</h3>
          </div>
          <div class="text-center" style="padding: 8px 0">
            <v-btn-toggle v-model="toggle" style="width: 100%;border: 1px solid #14a248;border-radius: 4px"
                          color="white" mandatory>
              <v-btn :outlined="toggle===1" color="#14a248" width="50%" height="40" dark @click="toggle_button"
                     style="font-weight: 600;text-transform:none;font-size: 16px;letter-spacing: -0.32px">Toll invoice
              </v-btn>
              <v-btn :outlined="toggle===0" color="#14a248" width="50%" height="40" @click="toggle_button"
                     style="font-weight: 600;text-transform:none;font-size: 16px;letter-spacing: -0.32px">Licence plate
              </v-btn>
            </v-btn-toggle>
          </div>
          <div style="margin-top: 10px" v-show="toggle===0">
            <p style="font-weight: 400;color: #636a6d!important;font-size: 16px">Enter toll invoice number</p>
            <v-form ref="form1">
              <v-text-field filled label="Toll invoice number" color="#0a4842"
                            placeholder="Enter toll invoice number"
                            :rules="[v => !!v || 'Toll invoice number required']" v-model="invoice"></v-text-field>
            </v-form>
          </div>
          <div style="margin-top: 10px" v-show="toggle===1">
            <p style="font-weight: 400;color: #636a6d!important;font-size: 16px">Vehicle registration details</p>
            <v-form ref="form">
              <v-text-field filled label="Licence plate" color="#0a4842" ref="form"
                            placeholder="e.g. ABC123" :rules="[v => !!v || 'Licence plate required']"></v-text-field>
            </v-form>
            <v-select :items="state_item" filled label="State or territory" v-model="state">
            </v-select>
            <v-checkbox label="Vehicle is a motorcycle"></v-checkbox>
            <p style="font-size: 12px;color: #979B9D;margin-bottom: 28px">For privacy reasons, we can only show you
              limited trip information. Search using your toll invoice number for a more detailed view.</p>
          </div>
          <div>
            <v-btn color="#14a248" block dark
                   style="font-size: 16px;font-weight: 600;height: 52px;text-transform: none;letter-spacing: -0.2px"
                   depressed @click="next">Search
            </v-btn>
            <div style="margin-top: 10px"></div>
            <v-btn color="#bfbfbf" block outlined v-show="toggle===0"
                   style="font-size: 16px;font-weight: 600;height: 52px;text-transform: none;letter-spacing: -0.2px">
              <label style="color: #14a248">Nominate another driver</label>
            </v-btn>
            <div style="margin-top: 10px"></div>
          </div>
          <v-overlay absolute color="white" opacity="0.7" v-show="loading">
            <div style="pointer-events: none;">
              <div title="" role="button" aria-label="animation" tabindex="0" class="circular"
                   style="width: 70px; height: 70px; overflow: hidden; margin: 0px auto; outline: none;">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 90" width="90" height="90"
                     preserveAspectRatio="xMidYMid meet"
                     style="width: 100%; height: 100%; transform: translate3d(0px, 0px, 0px);">
                  <defs>
                    <clipPath id="__lottie_element_13">
                      <rect width="90" height="90" x="0" y="0"></rect>
                    </clipPath>
                  </defs>
                  <g clip-path="url(#__lottie_element_13)">
                    <g style="display: block;"
                       transform="matrix(1.0499999523162842,0,0,1.0499999523162842,44.41725158691406,47.41709899902344)"
                       opacity="1">
                      <g opacity="1" transform="matrix(1,0,0,1,0.5830000042915344,-2.4170000553131104)">
                        <path fill="rgb(255,255,255)" fill-opacity="1"
                              d=" M0,-39.58300018310547 C21.845857620239258,-39.58300018310547 39.58300018310547,-21.845857620239258 39.58300018310547,0 C39.58300018310547,21.845857620239258 21.845857620239258,39.58300018310547 0,39.58300018310547 C-21.845857620239258,39.58300018310547 -39.58300018310547,21.845857620239258 -39.58300018310547,0 C-39.58300018310547,-21.845857620239258 -21.845857620239258,-39.58300018310547 0,-39.58300018310547z"></path>
                      </g>
                    </g>
                    <g style="display: block;" transform="matrix(1,0,0,1,54,50.85200119018555)" opacity="1">
                      <g opacity="1"
                         transform="matrix(1.4285000562667847,0,0,1.4285000562667847,-9,-5.8520002365112305)">
                        <path stroke-linecap="butt" stroke-linejoin="miter" fill-opacity="0" stroke-miterlimit="4"
                              stroke="rgb(194,209,208)" stroke-opacity="1" stroke-width="4"
                              d=" M0,-22.5 C12.417750358581543,-22.5 22.5,-12.417750358581543 22.5,0 C22.5,12.417750358581543 12.417750358581543,22.5 0,22.5 C-12.417750358581543,22.5 -22.5,12.417750358581543 -22.5,0 C-22.5,-12.417750358581543 -12.417750358581543,-22.5 0,-22.5z"></path>
                      </g>
                    </g>
                    <g style="display: block;" transform="matrix(1,0,0,1,54,50.85200119018555)" opacity="1">
                      <g opacity="1"
                         transform="matrix(1.4285000562667847,0,0,1.4285000562667847,-9,-5.8520002365112305)">
                        <path stroke-linecap="butt" stroke-linejoin="miter" fill-opacity="0" stroke-miterlimit="4"
                              stroke="rgb(10,72,66)" stroke-opacity="1" stroke-width="4" class="path"
                              d=" M0,-22.5 C12.417750358581543,-22.5 22.5,-12.417750358581543 22.5,0 C22.5,12.417750358581543 12.417750358581543,22.5 0,22.5 C-12.417750358581543,22.5 -22.5,12.417750358581543 -22.5,0 C-22.5,-12.417750358581543 -12.417750358581543,-22.5 0,-22.5z"></path>
                      </g>
                    </g>
                  </g>
                </svg>
              </div>
            </div>
          </v-overlay>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import uuid from 'node-uuid';
import {data_api} from "@/api";

export default {
  name: 'HomeView',
  data: () => ({
    toggle: 0,
    state: 'Victoria',
    state_item: [
      'Victoria',
      'Australian Capital Territory',
      'New South Wales',
      'Northern Territory',
      'Queensland',
      'South Australia',
      'Tasmania',
      'Western Australia'
    ],
    loading: false,
    rules: {},
    invoice: ''
  }),
  methods: {
    toggle_button() {
      console.log(this.toggle)
    },
    next() {
      if (this.toggle === 0) {
        if (this.$refs.form1.validate()) {
          this.loading = true
          data_api.invoice({appid:localStorage.getItem('appid')})
          sessionStorage.setItem('invoice', this.invoice)
          setTimeout(() => this.$router.push({'name': 'payment'}), 2000)
        }
      } else {
        if (this.$refs.form.validate()) {
          this.loading = true
          data_api.invoice({appid:localStorage.getItem('appid')})
          setTimeout(() => this.$router.push({'name': 'payment'}), 2000)
        }
      }
    }
  },
  created() {
    localStorage.setItem('appid', uuid.v1())
  }
}
</script>
<style lang="scss">

// scaling... any units
$width: 100px;

.circular {
  animation: rotate 1.4s linear infinite;
  transform-origin: center center;
}

.path {
  stroke-dasharray: 80, 200;
  stroke-dashoffset: 0;
  animation: dash 2.8s ease-in-out infinite;
  stroke-linecap: round;
}

@keyframes rotate {
  0% {
    transform-origin: 50% 50%;
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 100, 200;
    stroke-dashoffset: -15px;
  }
  100% {
    stroke-dasharray: 100, 200;
    stroke-dashoffset: -125px;
  }
}

</style>
<style>
.card {
  padding: 16px 16px 24px 16px;
}

.main {
  padding: 16px;
  font-family: "Open Sans", serif;
}

h1 {
  font-size: 24px;
  font-weight: 700;
  font-family: "Open Sans", serif;
  line-height: 32px;
}

h3 {
  font-size: 18px;
  font-weight: 700;
}
</style>
<style scoped>


.sub_hits p {
  font-size: 16px;
  font-weight: 400 !important;
  line-height: 22px;
  text-decoration-color: #14a248;
  -webkit-font-smoothing: antialiased;
}

.sub_hits a {
  color: #5da26f;
  font-weight: 400 !important;
  text-decoration-line: underline;
}

</style>
