<template>
  <v-app>
    <div style="display: flex;height: 52px;position: relative;box-shadow: 0 1px 2px rgb(0 0 0 / 25%);z-index: 101;">
      <section class="section_class">
        <div style="padding-right: 10px;border-right: 1px solid #cacdce;justify-content: center;height: 100%">
          <v-icon size="37" color="#2f383c" style="padding: 5px 0 0 6px">mdi-menu</v-icon>
        </div>
        <div style="margin-right: 40px;padding-top: 6px;padding-left: 20px">
          <img alt="logo" src="@/assets/logo.svg" width="75">
        </div>
      </section>

      <section class="second_section">
        <div style="padding: 0 8px;border-left: 1px solid #cacdce;height: 100%;min-width: 60px;">
          <img alt="help" src="@/assets/help-question.svg">
        </div>
        <div style="padding: 0 8px;border-left: 1px solid #cacdce;height: 100%;min-width: 60px">
          <img alt="help" src="@/assets/mob-logged-out.svg">
        </div>
      </section>
    </div>
    <v-main>
      <router-view/>
      <div class="flat_button">
        <span> Need help? </span>
      </div>
    </v-main>
    <div class="footer">
      <div style="padding: 0 24px">
        <div class="footer-wrap">
          <div class="text">Operated by</div>
          <img alt="logo-transurban" src="@/assets/logo-Transurban.png" width="188">
        </div>
      </div>
      <div style="padding-top: 40px;padding-right: 40px">
        <ul style="list-style: none">
          <li><a>About us</a></li>
          <li><a>Privacy</a></li>
          <li><a>Legal</a></li>
          <li><a>Contact us</a></li>
          <li><a>Help</a></li>
        </ul>
      </div>
      <div style="padding: 0 64px 0 24px;font-family: 'Open Sans',serif;font-size: 14px;line-height: 21px" class="white--text">
        <p>Linkt is a trade mark of Transurban Limited ABN 96 098 143 410</p>
      </div>
      <div style="padding: 24px 24px 0;">
        <p><img alt="play" src="@/assets/btn-playstore-178x53.svg"><br>
        <img alt="play" src="@/assets/App_Store_Badge_135x40.svg">
        </p>
      </div>
    </div>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),
};
</script>
<style>
.section_class {
  align-items: center;
  display: flex;
  padding: 0 8px;
  flex: 1 1 auto;
  justify-content: start;
  min-width: 0;
  order: -1;
}

.second_section {
  align-items: center;
  display: flex;
  flex-basis: auto;
  flex-grow: 1;
  flex-shrink: 1;
  justify-content: flex-end;
}

.second_section img {
  width: 25px;
  height: 25px;
}

.second_section img {
  transform: translateY(50%);
  margin: 0 20%;
}

.footer {
  background-color: #2f383c;
  padding: 60px 0;
}

.footer-wrap {
  min-width: 100%;
  text-align: left;
  padding-bottom: 40px;
  border-bottom: 1px solid #636a6d;
}

.text {
  font-family: 'Open Sans',serif;
  margin-bottom: 8px;
  color: #b1b0ad;
  font-size: 16px
}

.footer li {
  padding-bottom: 24px;
}

.footer a {
  font-size: 16px;
  font-weight: 600;
  font-family: "Open Sans", serif;
  border-bottom: 2px solid;
  border-color: rgba(255, 255, 255, .25);
  box-sizing: border-box;
  transition: border-color .15s linear;
  color: #fff !important;
  letter-spacing: 0;
}
.flat_button span {
  height: 18px;
  font-size: 13px;
  font-family: elevio-noto-sans, Helvetica, Arial, sans-serif;
  font-weight: 400;
  display: block;
  color: #fff;
}

.flat_button {
  z-index: 999;
  position: fixed;
  top: calc(50vh);
  right: -32px;
  transform: rotate(270deg);
  writing-mode: horizontal-tb !important;
  padding: 10px 15px;
  width: 100px;
  background-color: #14a248;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  box-shadow: 0 0 8px 0 rgb(0 0 0 / 25%);
}
</style>
